body {
  // background: #f2f3f7;
}

.newarrival {
  background: #ffff;
  border: 0;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 5%) 0px 15px 64px 0px;
  // .slick-slider {
  .slick-prev {
    left: -13px;
    /* background: red; */
    z-index: 2;
    background: #c6d75f;
    width: 25px;
    height: 80px;
    &:hover {
      background: #c6d75f;
    }
  }
  .slick-next {
    right: -14px;
    background: #c6d75f;
    width: 25px;
    height: 80px;
    &:hover {
      background: #c6d75f;
    }
  }
  // }
}
.center_heading_two {
  > h2 {
    text-transform: capitalize;
    color: #333;
  }
  .heading_border {
    display: none;
  }
}

// mobile category
.customfont {
  .title {
    font-family: inherit !important;
    a {
      color: #333 !important;
    }
  }
}
.mobile-category {
  border-radius: 10px 30px 0px 30px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(5px);
  .content {
    background-color: transparent;
  }
  .title a {
    // color: #fff !important;
  }
}

.back1 {
  background-color: #47525d59;
}
.back2 {
  background-color: #ffc10769;
}
.back3 {
  background-color: #17a2b84a;
}
.back4 {
  background-color: #c6d75f66;
}
.back5 {
  background-color: #28a74580;
}
.back6 {
  background-color: hsl(11deg 80% 45% / 37%);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .back6 {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}
.rounded {
  // border-radius: 50% !important;
  padding: 10px;
  // height: 120px;
  // width: auto;
}

.deals {
  .content .title a {
    color: #fff !important;
  }
  // transition: 0.4s all ease-in-out;
  cursor: pointer;
  padding: 0;
  border: 0;
  border-radius: 8px;
  // background-color: rgb(255, 255, 255);
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  border-radius: 8px;
  background-image: linear-gradient(
      rgba(43, 52, 69, 0.8),
      rgba(43, 52, 69, 0.8)
    ),
    url("../../../assets/img/ban_01.jpg");
  background-size: cover;
  background-position: center center;
  color: white;
  .thumb {
    padding: 10px;
  }
  .content .title a,
  .price_crd {
    color: #fff;
  }
  &:hover {
    .content .title a,
    .price_crd {
      color: #fff;
    }
  }
  .hurry_up {
    padding-bottom: 8px;
  }
}

.feature-product {
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  padding: 0;
  border-radius: 8px;
  .hurry_up {
    padding-bottom: 8px;
  }
  .image img {
    padding: 5px;
  }
  .total_pro_card .add_cart_qty {
    display: flex;
    padding: 0px 3px;
  }
  &:hover {
    // background: #e2e2e2;
  }
}
.norma-products {
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  &:hover {
    background: #e2e2e2;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .deals,
  .feature-product {
    .content {
      padding: 10px;
    }
  }
}

// offers
.product_wrappers_one {
  position: relative;
  overflow: initial !important;
}
.ribbon-corner.ribbon-fold {
  --tor-ribbon-polygon: polygon(
    0% 100%,
    50% calc(100% - (var(--tor-ribbon-size) + 1em)),
    100% 100%,
    100% calc(100% + 0.5em),
    0% calc(100% + 0.5em)
  ) !important;
  // margin: -0.34em;
}

.ribbon-corner.ribbon-fold:before {
  --tor-ribbon-fold-polygon: polygon(
    0% -10px,
    100% -10px,
    100% 100%,
    calc(100% - 0.5em - 10px) -10px,
    calc(0.5em + 10px) -10px,
    0% 100%
  ) !important;
  filter: brightness(85%);
  -webkit-clip-path: var(--tor-ribbon-fold-polygon);
  clip-path: var(--tor-ribbon-fold-polygon);
  content: "";
  position: absolute;
  bottom: calc(-0.5em + 1px);
  left: 0;
  width: 100%;
  height: 0.5em;
  z-index: -1;
  background-color: inherit;
}

.ribbon-corner {
  background-color: #c6d75f;
  --tor-ribbon-size: 4em;
  --tor-ribbon-rotate: 45deg;
  --tor-ribbon-translateX: 50%;
  --tor-ribbon-translateY: calc((var(--tor-ribbon-size) + 1em) - 100%);
  --tor-ribbon-origin: 100% 0%;
  --tor-ribbon-polygon: polygon(
    0% 100%,
    50% calc(100% - (var(--tor-ribbon-size) + 1em)),
    100% 100%
  ) !important;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-clip-path: var(--tor-ribbon-polygon);
  clip-path: var(--tor-ribbon-polygon);
  transform: rotate(var(--tor-ribbon-rotate))
    translateY(var(--tor-ribbon-translateY))
    translateX(var(--tor-ribbon-translateX));
  transform-origin: var(--tor-ribbon-origin);
  width: calc((var(--tor-ribbon-size) + 1em) * 2.25);
  height: 1.8em;
  color: #333;
  font-weight: bold;
}
.ribbon-corner {
  --tor-ribbon-size: 3em;
  z-index: 343;

  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    font-family: cursive;
    font-size: 12px;
  }
}

.festival {
  .content .title {
    .re_points {
      display: none;
    }
    .price_crd,
    .total_pro_card {
      display: none;
    }
  }
  .total_pro_card,
  .hurry_up {
    display: none;
  }
}
